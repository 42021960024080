// import RoutingContainer from "./components/RoutingContainer";
import React from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userState } from "./atoms";
import "./App.css";
// import "./RoutingContainer.css";
import Home from "./components/Home";
import Echo from "./components/Echo";
import Protected from "./components/Protected";
import OpenData from "./components/OpenData";
import ClosedData from "./components/ClosedData";
import Login from "./components/Login";

function PrivateRoute({ children, ...rest }) {
  const [user] = useRecoilState(userState);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/protected",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function App() {
  const [user] = useRecoilState(userState);
  return (
    <div className="App">
      <div className="navi nav">
        <header>
          <h3 className="nav-logo" style={{ color: "#4C1D95" }}>
            👾 DataMonster
          </h3>
          <nav>
            <Link to="/">Home</Link> / <Link to="/echo">Closed</Link> /{" "}
            <Link to="/open">Open</Link> / <Link to="/dataopen">Open Data</Link>{" "}
            / <Link to="/closeddata">Closed Data</Link> /{" "}
            {user ? (
              <Link to="/login">Logout</Link>
            ) : (
              <Link to="/login">Login</Link>
            )}{" "}
            <button className="login-btn">Login here</button>
          </nav>
        </header>
      </div>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/open" component={Echo} />
        <Route exact path="/dataopen" component={OpenData} />
        <Route exact path="/protected" component={Protected} />
        <Route exact path="/login" component={Login} />
        <PrivateRoute path="/echo">
          <Echo />
        </PrivateRoute>
        <PrivateRoute path="/closeddata">
          <ClosedData />
        </PrivateRoute>
      </Switch>
    </div>
  );
}

export default App;
