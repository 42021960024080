import React from "react";

function Protected() {
  return (
    <div>
      <h3>Page denied. Try to login.</h3>
    </div>
  );
}

export default Protected;
