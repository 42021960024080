import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: "https://assorted-egg.eu-central-1.aws.cloud.dgraph.io/graphql",
});

const authLink = setContext((_, { headers }) => {
  const usertoken = JSON.parse(localStorage.getItem("usertoken"));
  // const usertoken = authService.token;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      xappauth: usertoken ? `${usertoken.token}` : "",
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
