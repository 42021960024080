import React from "react";
import { gql, useQuery } from "@apollo/client";

const getDogsQuery = gql`
  query GetDogs {
    queryDog {
      id
      name
      color
    }
  }
`;

function OpenData() {
  const { loading, error, data } = useQuery(getDogsQuery);

  if (loading)
    return (
      <i
        className="fa fa-circle-o-notch fa-spin"
        style={{ color: "#4C1D95" }}
      ></i>
    );

  if (error) return <p>Error! Please try again...(</p>;

  return data.queryDog.map(({ id, name, color }) => (
    <div key={id}>
      <p>
        <strong>{name}</strong>
        <br />
        Farbe: {color}
        <br />
      </p>
    </div>
  ));
}

export default OpenData;
