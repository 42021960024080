import { atom } from "recoil";

export const userState = atom({
  key: "userState", // unique ID (with respect to other atoms/selectors)
  default:
    (JSON.parse(localStorage.getItem("usertoken")) &&
      JSON.parse(localStorage.getItem("usertoken")).token) ||
    "", // default value (aka initial value)
});

export const textState = atom({
  key: "textState", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});
