import React from "react";
import { useRecoilState } from "recoil";
import { textState } from "../atoms";

function Echo() {
  const [text, setText] = useRecoilState(textState);

  const onChange = (event) => {
    setText(event.target.value);
  };

  return (
    <div>
      <h4>Echo component</h4>
      <label htmlFor="echo">Enter sth... </label>
      <input name="echo" type="text" value={text} onChange={onChange} />
      <br />
      Echo: {text}
    </div>
  );
}

export default Echo;
