import React from "react";
import { useRecoilState } from "recoil";
import { userState } from "../atoms";

const URL = "https://exprlogin-igdfwm6cna-ew.a.run.app";

// function logout() {
//   setUser("");
//   localStorage.removeItem("usertoken");
//   //   client.resetStore();
// }

function Login() {
  const [user, setUser] = useRecoilState(userState);

  async function doLogin() {
    const email = "frodo@dgraph.io";
    const password = "huibuh";
    const res = await fetch(URL + "/testy", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });
    const data = await res.json();
    const token = data.tokens.usertoken;
    const expires = data.expires;
    setUser(token);
    localStorage.setItem(
      "usertoken",
      JSON.stringify({ token: token, expires: expires })
    );
    //   client.resetStore();
  }

  function doLogout() {
    setUser("");
    localStorage.removeItem("usertoken");
    // client.resetStore();
  }

  return (
    <div>
      {user ? (
        <>
          <h5>Logout</h5>
          <button
            style={{ backgroundColor: "#EF4444", fontSize: "14px" }}
            onClick={doLogout}
          >
            Logout here
          </button>
        </>
      ) : (
        <>
          <h5>Login</h5>
          <button
            style={{ backgroundColor: "#8B5CF6", fontSize: "14px" }}
            onClick={doLogin}
          >
            Login here
          </button>
        </>
      )}
      <p style={{ color: "#ff6700" }}>{user}</p>
    </div>
  );
}

export default Login;
