import React from "react";
import { useReactiveVar } from "@apollo/client";
import { cartItemsVar } from "../rvars";
import "./Home.css";

const bam = () => {
  cartItemsVar([1331]);
};

const bamBack = () => {
  cartItemsVar([456]);
};

function Home() {
  const otto = useReactiveVar(cartItemsVar);

  return (
    <div>
      <h2>Home</h2>
      <p>Data from ReactiveVar: {otto}</p>
      <button onClick={bam}>Change value</button>
      <p></p>
      <button onClick={bamBack}>Change value back</button>
    </div>
  );
}

export default Home;
