import { makeVar } from "@apollo/client";

export const cartItemsVar = makeVar([456]);

// cartItemsVar([100, 101, 102]);

// Output: [100, 101, 102]
// console.log(cartItemsVar());

// cartItemsVar([456]);
// Output: [456]
// console.log(cartItemsVar());
