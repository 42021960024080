import React from "react";
import { gql, useQuery } from "@apollo/client";

const getPersonsQuery = gql`
  query MyQuery {
    queryPerson(first: 10) {
      id
      first_name
      last_name
      email
    }
  }
`;

function ClosedData() {
  const { loading, error, data } = useQuery(getPersonsQuery);

  if (loading) return <p>Loading...</p>;

  if (error) return <p>Error! Please try again... {error}(</p>;

  return data.queryPerson.map(({ id, first_name, last_name, email }) => (
    <div key={id}>
      <p>
        <strong>{last_name}</strong>
        <br />
        <i>Vorname:</i> {first_name}
        <br />
        <i>Email:</i> {email}
      </p>
    </div>
  ));
}

export default ClosedData;
